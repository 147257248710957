'use client';

import { Check, X } from 'lucide-react';
import Image from 'next/image';
import type { ReactNode } from 'react';
import { Typo } from '~/components/atoms/typography/Typo';
import { Badge } from '~/components/catalyst/badge-catalyst';
import {
  TooltipBase,
  TooltipContent,
  TooltipTrigger,
} from '~/components/ui/tooltip';
import { cn } from '~/lib';
import { SiteConfig } from '~/lib/site-config';
import { Section } from './Section';

export type HeroProps = {};

export const Comparison = (props: HeroProps) => {
  return (
    <Section as="main" padding="lg" gap="lg" className="relative flex flex-col">
      <div className="m-auto flex w-full max-w-xl flex-col items-center gap-2 text-center">
        <Badge size="lg" color="blue">
          First, let's talk about our differences
        </Badge>
        <Typo variant="h1" as="h2">
          Why choose {SiteConfig.name} ?
        </Typo>
        <Typo className="text-lg font-light text-muted-foreground">
          There is a ton of tools out there, but we believe that <b>developers</b>{' '}
          deserve a better experience.
        </Typo>
      </div>
      <div className="m-auto grid w-full max-w-4xl grid-cols-1 gap-4 px-4 md:grid-cols-2 md:gap-8">
        <Typo variant="h2" as="p" className="hidden text-center md:block">
          Other tools
        </Typo>
        <Typo variant="h2" as="p" className="hidden text-center md:block">
          {SiteConfig.name}
        </Typo>
        <Typo variant="h2" as="p" className="text-center md:hidden">
          <span className="text-red-500">Other tools</span> VS{' '}
          <span className="text-green-500">{SiteConfig.name}</span>
        </Typo>
        <ComparisonCard variant="red">
          <Typo variant="large">Video focused</Typo>
          <Typo variant="default" className="mt-2 text-red-200">
            In typical courses, you only have videos and a few text.
          </Typo>
          <Image
            src="/images/video-focused.png"
            alt="Video focused"
            width={400}
            height={300}
            className="mt-4 h-auto w-full"
          />
        </ComparisonCard>
        <ComparisonCard variant="green">
          <Typo variant="large">Multi-modal</Typo>
          <Typo variant="muted" className="mt-2 text-green-200">
            We believe that the best way to learn is to use text, playground, videos
            and quizzes.
          </Typo>
          <Image
            src="/images/multi-modal.gif"
            alt="Codeline is multi-modal, contain video, quizzes, playground and text"
            width={400}
            height={300}
            className="mt-4 h-auto w-full"
          />
        </ComparisonCard>
        <ComparisonCard variant="red">
          <Typo variant="large">No practice</Typo>
          <Typo variant="default" className="mt-2 text-red-200">
            People need to practice a lot to get good at coding.
          </Typo>
        </ComparisonCard>
        <ComparisonCard variant="green">
          <Typo variant="large">Inline playground</Typo>
          <Typo variant="muted" className="mt-2 text-green-200">
            Make people practice by adding a playground directly inside the course.
          </Typo>
          <Image
            src="/images/playground.gif"
            alt="Codeline is multi-modal, contain video, quizzes, playground and text"
            width={400}
            height={300}
            className="mt-4 h-auto w-full"
          />
        </ComparisonCard>
        <ComparisonCard variant="red">
          <Typo variant="large">Optimized for selling</Typo>
          <Typo variant="default" className="mt-2 text-red-200">
            Most application is just optimized for selling, and not delivering the
            courses.
          </Typo>
          <ul className="mt-4 flex flex-col gap-2">
            <li className="flex items-center gap-2">
              <X className="text-red-500" />
              <span>No easy reports section</span>
            </li>
            <li className="flex items-center gap-2">
              <X className="text-red-500" />
              <span>No reminders</span>
            </li>
            <li className="flex items-center gap-2">
              <X className="text-red-500" />
              <span>No comments</span>
            </li>
          </ul>
        </ComparisonCard>
        <ComparisonCard variant="green">
          <Typo variant="large">Optimizing for delivering</Typo>
          <Typo variant="muted" className="mt-2 text-green-200">
            With Codeline, everything is made for <b>delivering</b> the best possible
            courses.
          </Typo>
          <ul className="mt-4 flex flex-col gap-2">
            <TooltipBase>
              <TooltipTrigger asChild>
                <li className="flex items-start gap-2">
                  <Check className="text-green-500" />
                  <Typo as="span" className="underline decoration-dashed">
                    User can report bugs anywhere just by selecting text
                  </Typo>
                </li>
              </TooltipTrigger>
              <TooltipContent className="flex max-w-md flex-col gap-2 p-8">
                <Typo variant="muted">
                  You will receive the bug report in a specific page or in Discord
                  notification.
                </Typo>
                <Image
                  src="/images/bug-report.gif"
                  alt="Bug report"
                  className="h-auto w-full border-2 border-border"
                  width={400}
                  height={300}
                />
              </TooltipContent>
            </TooltipBase>
            <li className="flex items-center gap-2">
              <Check className="text-green-500" />
              <span>User get weekly reports and reminder to continue</span>
            </li>
            <li className="flex items-center gap-2">
              <Check className="text-green-500" />
              <span>User can comment on the course and helping each other</span>
            </li>
          </ul>
        </ComparisonCard>
      </div>
    </Section>
  );
};

type ComparisonCardProps = {
  variant: 'red' | 'green';
  children: ReactNode;
};

export const ComparisonCard = ({ variant, children }: ComparisonCardProps) => {
  const isRed = variant === 'red';
  return (
    <div
      className={cn(
        'h-fit border p-4 shadow-md md:p-8',
        isRed
          ? 'border-red-500/80 bg-red-500/20'
          : 'border-green-500/80 bg-green-500/20'
      )}
    >
      {children}
    </div>
  );
};
